import React from "react";
import "./TermsOfService.css";

const TermsOfService = () => {
  return (
    <div className="terms-of-service-container">
      <h1 className="terms-of-service-title">Hizmet Şartları</h1>
      <p className="terms-of-service-description">
        Web uygulamamızı kullanarak, aşağıdaki şartları kabul etmiş olursunuz.
        Bu şartlar, kullanıcıların hizmetlerimizi nasıl kullanabileceği ve
        hangi kurallara uyması gerektiği konusunda bilgi verir.
      </p>

      <section className="policy-section">
        <h2 className="policy-section-title">Abonelik</h2>
        <p className="policy-section-description">
          Abonelik planları, hizmetlerin belirli bir süre boyunca sağlanmasını
          içerir. Ücretler, aylık, yıllık veya uzun vadeli ödeme seçenekleriyle
          yapılabilir.
        </p>
      </section>

      <section className="policy-section">
        <h2 className="policy-section-title">Hizmetlerin Kullanımı</h2>
        <p className="policy-section-description">
          Web uygulamamızı, yalnızca yasal amaçlarla ve belirlenen kurallar
          çerçevesinde kullanmalısınız. Herhangi bir kötüye kullanım, hesap
          askıya alınmasına neden olabilir.
        </p>
      </section>
    </div>
  );
};

export default TermsOfService;
