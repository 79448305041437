import React from "react";
import "./CookiePolicy.css";

const CookiePolicy = () => {
  return (
    <div className="cookie-policy-container">
      <h1 className="cookie-policy-title">Çerez Politikası</h1>
      <p className="cookie-policy-description">
        Web sitemiz, kullanıcı deneyimini iyileştirmek için çerezler kullanmaktadır.
        Bu çerezler, web sitesi kullanım verilerini toplar ve sitenin işlevselliğini
        artırır. Çerez kullanımı, sitenin bazı özelliklerinin düzgün çalışmasını sağlar.
      </p>

      <section className="cookie-section">
        <h2 className="cookie-section-title">Çerez Kullanımı</h2>
        <p className="cookie-section-description">
          Çerezler, kullanıcıların önceki ziyaretlerini hatırlayarak, siteyi daha
          kişiselleştirilmiş bir şekilde sunmamıza olanak tanır.
        </p>
      </section>

      <section className="cookie-section">
        <h2 className="cookie-section-title">Çerez Yönetimi</h2>
        <p className="cookie-section-description">
          Çerezleri tarayıcı ayarlarınızdan yönetebilirsiniz. Ancak, çerezleri
          devre dışı bırakmak, web sitesinin bazı özelliklerinin düzgün çalışmamasına
          yol açabilir.
        </p>
      </section>
 </div>
  );
};

export default CookiePolicy;
