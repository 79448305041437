import React, { useContext, useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { FaSignOutAlt, FaBars } from "react-icons/fa";
import logo from "../assets/logos/logo.svg";
import "./Navbar.css";

const Navbar = () => {
  const { user, logout } = useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dropdownRef = useRef(null);
  const hamburgerRef = useRef(null);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target))
      setIsDropdownOpen(false);
    if (hamburgerRef.current && !hamburgerRef.current.contains(event.target))
      setIsMenuOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, []);

  return (
    <nav className="navbar-container">
      <Link to="/" className="navbar-logo-link">
        <img src={logo} alt="Logo" className="navbar-logo" />
      </Link>

      <div
        ref={hamburgerRef}
        className={`navbar-links-container ${isMenuOpen ? "open" : ""}`}
      >
        <Link
          to="/"
          className={`navbar-link ${location.pathname === "/" ? "active" : ""}`}
        >
          Anasayfa
        </Link>

        {!user && (
          <Link
            to="/login"
            className={`navbar-link ${
              location.pathname === "/login" ? "active" : ""
            }`}
          >
            Giriş
          </Link>
        )}

        {user && (
          <>
            <Link
              to="/analysis"
              className={`navbar-link ${
                location.pathname === "/analysis" ? "active" : ""
              }`}
            >
              Analizler
            </Link>
            <Link
              to="/custom-publish"
              className={`navbar-link ${
                location.pathname === "/custom-publish" ? "active" : ""
              }`}
            >
              Özel Yayınlar
            </Link>
            <Link
              to="/social-media-accounts"
              className={`navbar-link ${
                location.pathname === "/social-media-accounts" ? "active" : ""
              }`}
            >
              Sosyal Medya Hesapları
            </Link>

            <div
              ref={dropdownRef}
              className="dropdown-container"
              onClick={toggleDropdown}
            >
              <div className="navbar-link">
                <img
                  src={user?.profile_pic || "default-profile.png"}
                  alt="User Avatar"
                  className="navbar-profile-pic"
                />
                {user.username?.split("@")[0] || "User"}
              </div>

              {isDropdownOpen && (
                <div className="dropdown-menu">
                  <Link to="/profile" className="dropdown-link">
                    Hesap
                  </Link>
                  <Link to="/profile/settings" className="dropdown-link">
                    Ayarlar
                  </Link>
                  <button
                    onClick={handleLogout}
                    className="dropdown-link logout-button"
                  >
                    <FaSignOutAlt /> Çıkış Yap
                  </button>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <div className="hamburger" onClick={toggleMenu}>
        <FaBars size={24} />
      </div>
    </nav>
  );
};

export default Navbar;
