import React, { lazy, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import HomePage from "./pages/HomePage";

import ProfilePage from "./pages/ProfilePage";
import SocialMediaConnectPage from "./pages/SocialMediaConnectPage";
import AnalysisPage from "./pages/AnalysisPage";
import CustomPublishPage from "./pages/CustomePublishPage";
import SettingsPage from "./pages/SettingsPage";
import ContactPage from "./pages/ContactPage";
import OurServicePage from "./pages/OurServicePage";

import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";
import NotFoundPage from "./pages/NotFoundPage";
import PrivacyPolicy from "./Policies/PrivacyPolicy";
import TermsOfService from "./Policies/TermsOfService";
import DataDeletionPolicy from "./Policies/DataDeletionPolicy";
import CookiePolicy from "./Policies/CookiePolicy";
import ScrollToTop from "./utils/ScrollToTop";
import GoToTop from "./utils/GoToTop";

import Test from "./pages/Test";
import HelpPage from "./utils/HelpPage";
import PublicRoute from "./components/PublicRoute";

const LoginPage = lazy(() => import("./pages/LoginPage"));
const AboutUsPage = lazy(() => import("./pages/AboutUsPage"));
const BlogPage = lazy(() => import("./pages/BlogPage"));

function App() {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

  const handleThemeChange = (newTheme) => {
    setTheme(newTheme);
    document.body.classList.remove("light", "dark");
    document.body.classList.add(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  return (
    <div className={`App ${theme}`}>
      <AuthProvider>
        <Router>
          <Navbar />
          <ScrollToTop /> {/* Sayfa değiştirildiğinde scroll sıfırlama */}
          <div style={{ minHeight: "80vh", padding: "2em" }}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route
                path="/login"
                element={
                  <PublicRoute element={<LoginPage />} redirectTo="/profile" />
                }
              />
              <Route
                path="/profile"
                element={<PrivateRoute element={<ProfilePage />} />}
              />
              <Route
                path="/social-media-accounts"
                element={<PrivateRoute element={<SocialMediaConnectPage />} />}
              />
              <Route
                path="/analysis"
                element={<PrivateRoute element={<AnalysisPage />} />}
              />
              <Route
                path="/custom-publish"
                element={<PrivateRoute element={<CustomPublishPage />} />}
              />
              <Route
                path="/profile/settings"
                element={
                  <PrivateRoute
                    element={<SettingsPage onThemeChange={handleThemeChange} />}
                  />
                }
              />
              <Route path="/about-us" element={<AboutUsPage />} />
              <Route path="/contact-us" element={<ContactPage />} />
              <Route path="/our-services" element={<OurServicePage />} />
              <Route path="/blog" element={<BlogPage />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/deletion-policy" element={<DataDeletionPolicy />} />
              <Route path="/cookie-policy" element={<CookiePolicy />} />
              <Route path="/helper" element={<HelpPage />} />
              <Route path="/test" element={<Test />} />
              <Route path="*" element={<NotFoundPage />} />{" "}
              {/* Catch-all route */}
            </Routes>
          </div>
          <GoToTop /> {/* GoToTop butonu */}
          <Footer />
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
