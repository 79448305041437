import React from "react";
import "./HomePage.css";
import HeroSection from "../ui/HeroSection";
import PromotePage from "./PromotePage";
import VideoPage from "./VideoPage";
import BlogPage from "../pages/BlogPage";
import showGif from "../assets/videos/6.gif";

const HomePage = () => {
  return (
    <div className="homepage-container">
      <HeroSection />
      <VideoPage />
      <PromotePage />
      <BlogPage />
    </div>
  );
};

export default HomePage;
