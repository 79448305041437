import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/UseAuth";

const PublicRoute = ({ element, redirectTo = "/" }) => {
  const { user } = useAuth();

  // Eğer kullanıcı giriş yaptıysa, belirtilen sayfaya yönlendirin (default: "/")
  return user ? <Navigate to={redirectTo} /> : element;
};

export default PublicRoute;
