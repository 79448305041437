import React from "react";
import "./BlogPage.css";

const blogPosts = [
  {
    id: 1,
    title: "React ile Web Geliştirmeye Giriş",
    description: "React, bileşen tabanlı bir yapıya sahip popüler bir JavaScript kütüphanesidir.",
    date: "2024-11-05",
  },
  {
    id: 2,
    title: "JavaScript'in Temelleri",
    description: "Bu makalede, JavaScript dilinin temellerine ve en iyi uygulamalarına değineceğiz.",
    date: "2024-10-30",
  },
  {
    id: 3,
    title: "Web Tasarımında UX ve UI'nin Önemi",
    description: "İyi bir kullanıcı deneyimi (UX) ve kullanıcı arayüzü (UI) tasarımı, bir web sitesinin başarısında büyük rol oynar.",
    date: "2024-10-20",
  },
  {
    id: 4,
    title: "SEO ile Organik Trafiğinizi Artırın",
    description: "SEO, arama motorlarında daha iyi sıralama almanızı sağlar. Bu yazımızda SEO'nun temel stratejilerine değineceğiz.",
    date: "2024-10-10",
  },
];

const BlogPage = () => {
  return (
    <div className="blog-page">
      <h1 className="blog-title">Blog</h1>
      <div className="blog-list">
        {blogPosts.map((post) => (
          <div className="blog-card" key={post.id}>
            <h2 className="blog-card-title">{post.title}</h2>
            <p className="blog-card-date">{new Date(post.date).toLocaleDateString("tr-TR", { year: "numeric", month: "long", day: "numeric" })}</p>
            <p className="blog-card-description">{post.description}</p>
            <button className="blog-card-button">Devamını Oku</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogPage;
