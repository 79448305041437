import React, { useState, useEffect, memo } from "react";
import { FaSave, FaEdit, FaSpinner, FaKey, FaUpload } from "react-icons/fa";
import profilePicPlaceholder from "../assets/images/profile.jpg";
import { getProfile, updateProfile, changePassword } from "../api/ProfileApi";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./ProfilePage.css";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// Şifre gücünü belirleyen fonksiyon
const getPasswordStrength = (password) => {
  if (!password) return { score: 0, label: "Zayıf" };
  const strong = /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])/;
  const medium = /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])/;
  if (strong.test(password)) return { score: 100, label: "Güçlü" };
  if (medium.test(password)) return { score: 50, label: "Orta" };
  console.log(strong.test(password), medium.test(password));
  return { score: 25, label: "Zayıf" };
};

const ProfilePage = () => {
  const [isEditing, setIsEditing] = useState(false); // Profil düzenleme modu
  const [profileData, setProfileData] = useState({
    username: "",
    firstName: "",
    lastName: "",
    bio: "",
    email: "",
    phoneNumber: "",
    address: "",
    accountStatus: "",
    profileImageUrl: profilePicPlaceholder,
  });
  const [passwordChangeMode, setPasswordChangeMode] = useState(false); // Şifre değiştirme modu
  const [passwordData, setPasswordData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false); // Yükleniyor durumu
  const [profileImage, setProfileImage] = useState(null); // Profil fotoğrafı
  const [passwordStrength, setPasswordStrength] = useState({
    score: 0,
    label: "Zayıf",
  });

  const userId = localStorage.getItem("userId"); // Kullanıcı ID'sini al
  const chartData = {
    labels: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran"],
    datasets: [
      {
        label: "Yönetilen Hesaplar",
        data: [5, 7, 8, 9, 12, 15],
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
        tension: 0.4,
      },
      {
        label: "Profil Gücü",
        data: [50, 60, 70, 80, 85, 90],
        borderColor: "rgba(255, 99, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `Değer: ${tooltipItem.raw}`,
        },
      },
    },
    scales: {
      y: { beginAtZero: true },
    },
  };

  // Profil verilerini almak için useEffect hook'u
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const { profile, user } = await getProfile();
        setProfileData({
          username: user.username,
          firstName: profile.firstName,
          lastName: profile.lastName,
          bio: profile.bio,
          email: user.email,
          phoneNumber: profile.phoneNumber,
          address: profile.address,
          accountStatus: profile.accountStatus || "Premium Plan",
          profileImageUrl: profile.profileImageUrl || profilePicPlaceholder,
        });
      } catch (error) {
        console.error("Profil yüklenirken hata oluştu:", error);
      }
    };

    fetchProfileData();
  }, [userId]);

  // Profil verilerini düzenlemek için handler
  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prev) => ({ ...prev, [name]: value }));
  };

  // Şifre değişikliği verilerini düzenlemek için handler
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prev) => ({ ...prev, [name]: value }));
    console.log(name);
    if (name === "newPassword") {
      console.log(value);
      setPasswordStrength(getPasswordStrength(value));
    }
  };

  // Profil kaydetme işlemi
  const handleSaveProfile = async () => {
    setLoading(true);
    try {
      await updateProfile(profileData);
      if (profileImage) {
        // Resim yükleme işlemi
        console.log("Profil resmi yükleniyor:", profileImage);
      }
      setIsEditing(false);
    } catch (error) {
      console.error("Profil güncelleme hatası:", error);
    } finally {
      setLoading(false);
    }
  };

  // Şifre değiştirme işlemi
  const handlePasswordSubmit = async () => {
    setLoading(true);
    try {
      if (passwordData.newPassword !== passwordData.confirmPassword) {
        throw new Error("Şifreler uyuşmuyor!");
      }
      await changePassword(passwordData.oldPassword, passwordData.newPassword);
      setPasswordChangeMode(false);
    } catch (error) {
      console.error("Şifre değiştirme hatası:", error);
    } finally {
      setLoading(false);
    }
  };

  // Profil fotoğrafını değiştirme işlemi
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(file);
      setProfileData((prev) => ({
        ...prev,
        profileImageUrl: URL.createObjectURL(file),
      }));
    }
  };
  // Barın stilini belirlemek için sınıf ekleyelim
  let barClass = "weak"; // Varsayılan değer "weak"
  if (passwordStrength.score >= 50) barClass = "medium";
  if (passwordStrength.score === 100) barClass = "strong";
  return (
    <div className="profile-container">
      {/* Profil kartı */}
      <div className="profile-card">
        <div className="left-section">
          {/* Profil resmini göster */}
          <img
            src={profileData.profileImageUrl}
            alt="Profil Resmi"
            className="profile-image"
          />
          {isEditing && (
            <label className="image-upload">
              <FaUpload /> Resim Yükle
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </label>
          )}
          <h2 className="profile-name">{profileData.username}</h2>
          <p className="profile-role">Sosyal Medya Yöneticisi</p>
          <p className="subscription">{profileData.accountStatus}</p>
        </div>
        <div className="right-section">
          <div className="info-category">
            <h4>Profil Bilgilerim</h4>
            {isEditing ? (
              <div className="form">
                {/* Profil bilgilerini düzenle */}
                {Object.entries(profileData).map(
                  ([key, value]) =>
                    key !== "profileImageUrl" && (
                      <input
                        key={key}
                        type={key === "email" ? "email" : "text"}
                        name={key}
                        value={value}
                        onChange={handleProfileChange}
                        placeholder={key}
                      />
                    )
                )}
                <button
                  onClick={handleSaveProfile}
                  disabled={loading}
                  className="action-button"
                >
                  {loading ? <FaSpinner className="spinner" /> : <FaSave />}{" "}
                  Kaydet
                </button>
              </div>
            ) : (
              <div className="info">
                {/* Profil bilgilerini görüntüle */}
                {Object.entries(profileData).map(
                  ([key, value]) =>
                    key !== "profileImageUrl" && (
                      <p key={key}>
                        <strong>
                          {key.charAt(0).toUpperCase() + key.slice(1)}:
                        </strong>{" "}
                        {value}
                      </p>
                    )
                )}
                <button
                  onClick={() => setIsEditing(true)}
                  className="action-button"
                >
                  <FaEdit /> Düzenle
                </button>
              </div>
            )}
          </div>
          <div className="password-section">
            <h4>Şifre Değiştir</h4>
            {passwordChangeMode ? (
              <div className="form">
                {/* Şifre değiştirme formu */}
                <input
                  type="password"
                  name="oldPassword"
                  value={passwordData.oldPassword}
                  onChange={handlePasswordChange}
                  placeholder="Eski Şifre"
                />
                <input
                  type="password"
                  name="newPassword"
                  value={passwordData.newPassword}
                  onChange={handlePasswordChange}
                  placeholder="Yeni Şifre"
                />
                <input
                  type="password"
                  name="confirmPassword"
                  value={passwordData.confirmPassword}
                  onChange={handlePasswordChange}
                  placeholder="Şifreyi Onayla"
                />
                <div className="password-strength">
                  <div
                    className={`strength-bar ${barClass}`} // Dinamik sınıf ekleme
                    style={{ width: `${passwordStrength.score}%` }}
                  ></div>
                  <span className="strength-label">
                    {passwordStrength.label}
                  </span>
                </div>
                <button
                  onClick={handlePasswordSubmit}
                  disabled={loading}
                  className="action-button"
                >
                  {loading ? <FaSpinner className="spinner" /> : <FaKey />}{" "}
                  Değiştir
                </button>
              </div>
            ) : (
              <button
                onClick={() => setPasswordChangeMode(true)}
                className="action-button"
              >
                <FaKey /> Şifreyi Değiştir
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="chart-section">
        <h4>Analitikler</h4>
        <Line data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

export default memo(ProfilePage);
