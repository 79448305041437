import React, { useState } from "react";
import "./HelpPage.css";
import { FaStar, FaChartLine, FaCalendarCheck, FaBell } from "react-icons/fa";
import companyLogo from "../assets/logos/logo.svg"; // Example logo file
import profilePic1 from "../assets/images/profile.jpg";
import profilePic2 from "../assets/images/profile.jpg";

const HelpPage = () => {
  const faqs = [
    {
      question: "Sosyal medya hesaplarınızı nasıl yönetiyorsunuz?",
      answer:
        "Sosyal medya uzmanlarımız tüm platformlarınızı tek bir yerden yöneterek içerikleri planlıyor ve yayınlıyor.",
    },
    {
      question: "Analiz ve raporlama hizmetleri sağlıyor musunuz?",
      answer:
        "Evet, sosyal medya performansınızı analiz ediyor ve detaylı raporlarla size sunuyoruz.",
    },
    {
      question: "Hizmet ücretleriniz nedir?",
      answer:
        "Hizmetlerimizin ücretleri, işletmenizin ihtiyaçlarına ve istediğiniz hizmet kapsamına göre değişiklik göstermektedir.",
    },
    {
      question: "Müşteri desteği sağlıyor musunuz?",
      answer:
        "Evet, sosyal medya hesaplarınızla ilgili tüm sorularınız için müşteri destek ekibimiz yanınızda.",
    },
  ];

  const testimonials = [
    {
      name: "Ahmet Yılmaz",
      feedback:
        "Bu hizmet gerçekten harika! Sosyal medyadaki etkileşimim arttı.",
      rating: 5,
      profilePic: profilePic1,
    },
    {
      name: "Mehmet Demir",
      feedback: "Profesyonel bir ekip. Her aşamada destek verdiler.",
      rating: 4,
      profilePic: profilePic1,
    },
    {
      name: "Ayşe Kaya",
      feedback:
        "Sosyal medya hesaplarımız için en iyi çözüm oldu. Tavsiye ederim!",
      rating: 5,
      profilePic: profilePic2,
    },
    {
      name: "Fatma Arslan",
      feedback: "Hızlı ve etkili bir hizmet sundular. Çok memnun kaldık.",
      rating: 4,
      profilePic: profilePic1,
    },
  ];

  return (
    <div className="help-page">
      <header className="help-header">
        <img src={companyLogo} alt="Company Logo" className="company-logo" />
        <h1>Yardım Merkezi</h1>
      </header>

      <section className="intro">
        <h2>Uygulamaya Hoş Geldiniz!</h2>
        <p>
          Bu uygulama ile sosyal medya hesaplarınızı kolayca yönetebilir, analiz
          edebilir ve gönderi planlayabilirsiniz.
        </p>
      </section>

      <section className="features">
        <h2>Özellikler</h2>
        <div className="feature">
          <FaChartLine className="feature-icon" />
          <h3>Hesap Bağlama</h3>
          <p>
            Sosyal medya hesaplarınızı bağlamak için üst menüden "Hesap Bağla"
            seçeneğine tıklayın.
          </p>
        </div>
        <div className="feature">
          <FaCalendarCheck className="feature-icon" />
          <h3>Gönderi Planlama</h3>
          <p>
            Gönderi planlamak için "Gönderi Planla" sekmesine gidin. Zaman ve
            tarih belirledikten sonra gönderinizi oluşturun.
          </p>
        </div>
        <div className="feature">
          <FaBell className="feature-icon" />
          <h3>Bildirimler</h3>
          <p>
            Bağlantı durumunda bir değişiklik olduğunda veya yeni bir analiz
            verisi oluştuğunda bildirim alırsınız.
          </p>
        </div>
      </section>

      <section className="faq">
        <h2>Sıkça Sorulan Sorular (SSS)</h2>
        <div className="faq-list">
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </section>

      <section className="testimonials">
        <h2>Müşteri Yorumları</h2>
        {testimonials.map((item, index) => (
          <TestimonialItem
            key={index}
            feedback={item.feedback}
            rating={item.rating}
            name={item.name}
            profilePic={item.profilePic}
          />
        ))}
      </section>

      <section className="contact">
        <h2>Destek İletişim</h2>
        <p>
          Yardım almak için bize e-posta ile ulaşabilirsiniz:{" "}
          <a href="mailto:support@mdsiber.com">support@mdsiber.com</a>
        </p>
      </section>
    </div>
  );
};

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`faq-card ${isOpen ? "open" : ""}`}>
      <div className="faq-question" onClick={() => setIsOpen(!isOpen)}>
        {question}
        <span className="faq-icon">{isOpen ? "-" : "+"}</span>
      </div>
      {isOpen && <div className="faq-answer">{answer}</div>}
    </div>
  );
};

const TestimonialItem = ({ feedback, rating, name, profilePic }) => (
  <div className="testimonial">
    <img src={profilePic} alt={`${name}'s profile`} className="profile-pic" />
    <p className="feedback">"{feedback}"</p>
    <div className="rating">
      {Array.from({ length: rating }, (_, i) => (
        <FaStar key={i} color="#ffc107" />
      ))}
    </div>
    <p className="author">- {name}</p>
  </div>
);

export default HelpPage;
