import React from "react";
import { useNavigate } from "react-router-dom";
import "./NotFoundPage.css";
import notFoundPageImage from "../assets/images/notFound.png";

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <div className="not-found-container">
      {/* Left Side: Background Image */}
      <div className="not-found-left">
        <img
          src={notFoundPageImage}
          alt="Background illustration"
          className="not-found-background"
        />
      </div>

      {/* Right Side: Error Content */}
      <div className="not-found-right">
        <h1 className="not-found-title">404</h1>
        <p className="not-found-text">Üzgünüz, aradığınız sayfa bulunamadı.</p>
        <button
          className="not-found-button"
          onClick={handleGoHome}
          aria-label="Anasayfaya Dön"
        >
          Anasayfaya Dön
        </button>
      </div>
    </div>
  );
};

export default NotFoundPage;
