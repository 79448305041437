import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa"; // Font Awesome Iconları

const Footer = () => {
  return (
    <footer className="footer-container">
      <p>© 2024 Tüm Hakları Saklıdır</p>

      <div className="footer-links">
        <a href="/about-us" className="footer-link">
          Hakkında
        </a>
        <a href="/our-services" className="footer-link">
          Hizmetler
        </a>
        <a href="/contact-us" className="footer-link">
          İletişim
        </a>{" "}
        <a href="/blog" className="footer-link">
          Blog
        </a>
        <a href="/helper" className="footer-link">
          Yardım
        </a>{" "}
        <a href="/privacy-policy" className="footer-link">
          Gizlilik Politikası
        </a>
        <a href="/term-of-service" className="footer-link">
          Hizmet Şartları
        </a>
        <a href="/deletion-policy" className="footer-link">
          Veri Silme Politikası
        </a>
        <a href="/cookie-policy" className="footer-link">
          Çerez Politikası
        </a>
      </div>

      <div className="social-links">
        <a
          href="https://www.facebook.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Visit our Facebook page"
          className="social-icon"
        >
          <FaFacebook />
        </a>
        <a
          href="https://www.twitter.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Visit our Twitter page"
          className="social-icon"
        >
          <FaTwitter />
        </a>
        <a
          href="https://www.instagram.com"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Visit our Instagram page"
          className="social-icon"
        >
          <FaInstagram />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
