import React, { useState } from "react";
import "./ContactPage.css";
import { FaEnvelope, FaPhoneAlt, FaFax, FaWhatsapp } from "react-icons/fa"; // Importing FontAwesome icons

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you can handle the form submission (e.g., send to a backend)
    console.log("Form submitted: ", formData);
  };

  return (
    <div className="contact-page">
      <header className="contact-header">
        <h1>İletişim</h1>
        <p>Bizimle iletişime geçmek için aşağıdaki formu doldurun.</p>
      </header>

      <section className="contact-form-section">
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Adınız</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Adınızı girin"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">E-posta Adresiniz</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="E-posta adresinizi girin"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Mesajınız</label>
            <textarea
              id="message"
              name="message"
              placeholder="Mesajınızı yazın"
              value={formData.message}
              onChange={handleInputChange}
              required
            />
          </div>

          <button type="submit" className="submit-button">
            Gönder
          </button>
        </form>
      </section>

      {/* Additional Contact Information */}
      <section className="additional-contact-info">
        <h3>İletişim Bilgilerimiz</h3>
        <ul>
          <li>
            <FaEnvelope /> <strong> Email </strong>
            <a href="mailto:info@mdsiber.com">info@mdsiber.com</a>
          </li>
          <li>
            <FaPhoneAlt /> <strong> Telefon </strong>
            <a href="tel:+901234567890">+90 123 456 7890</a>
          </li>
          <li>
            <FaFax /> <strong> Fax </strong>
            <a href="fax:+901234567891">+90 123 456 7891</a>
          </li>
          <li>
            <FaWhatsapp />
            <strong> Whatsapp </strong>
            <a
              href="https://wa.me/901234567890"
              target="_blank"
              rel="noopener noreferrer"
            >
              +90 123 456 7890
            </a>
          </li>
        </ul>
      </section>
      <section className="contact-info">
        <h3>Adresimiz</h3>
        <p>Bağcılar Buğdaycılar Plaza A/44 Bağlar/Diyarbakır, Türkiye</p>
        <div className="map-container">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d2646.421924072364!2d40.17355549983179!3d37.92707135316343!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1str!2str!4v1730889426966!5m2!1str!2str"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </section>
    </div>
  );
};

export default ContactPage;
