import React from "react";

const Card = ({ title, description, imageUrl }) => {
  const cardStyle = {
    border: "1px solid #ddd",
    borderRadius: "10px",
    overflow: "hidden",
    boxShadow: "0 6px 12px rgba(0,0,0,0.15)",
    width: "250px",
    margin: "1em",
    transition: "transform 0.3s, box-shadow 0.3s",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
  };

  const cardHoverStyle = {
    transform: "scale(1.05)",
    boxShadow: "0 10px 20px rgba(0,0,0,0.2)",
  };

  const imageStyle = {
    width: "100%",
    height: "150px",
    objectFit: "cover",
  };

  const contentStyle = {
    padding: "1em",
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
  };

  const titleStyle = {
    margin: "0 0 0.5em",
    fontSize: "1.2em",
    color: "#000",
  };

  const descriptionStyle = {
    fontSize: "0.9em",
    color: "#555",
    flex: "1 1 auto",
  };

  const buttonStyle = {
    padding: "0.5em 1em",
    marginTop: "1em",
    border: "none",
    borderRadius: "4px",
    backgroundColor: "#000",
    color: "#fff",
    cursor: "pointer",
    alignSelf: "flex-start",
    transition: "background-color 0.3s",
  };

  return (
    <div
      style={cardStyle}
      onMouseEnter={(e) =>
        (e.currentTarget.style.transform = cardHoverStyle.transform)
      }
      onMouseLeave={(e) => (e.currentTarget.style.transform = "none")}
    >
      {imageUrl && <img src={imageUrl} alt={title} style={imageStyle} />}
      <div style={contentStyle}>
        <h3 style={titleStyle}>{title}</h3>
        <p style={descriptionStyle}>
          {description.length > 100
            ? `${description.slice(0, 100)}...`
            : description}
        </p>
        <button style={buttonStyle} onClick={() => alert(`${title} clicked`)}>
          Learn More
        </button>
      </div>
    </div>
  );
};

export default Card;
