import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1 className="privacy-policy-title">Gizlilik Politikası</h1>
      <p className="privacy-policy-description">
        Kullanıcılarımızın kişisel verilerini toplarken ve işlerken, en yüksek
        gizliliği sağlamak adına gerekli önlemleri almaktayız. Toplanan veriler
        yalnızca belirli hizmetlerimizin sağlanması amacıyla kullanılacak ve
        üçüncü şahıslarla paylaşılmayacaktır.
      </p>

      <section className="policy-section">
        <h2 className="policy-section-title">Veri Toplama</h2>
        <p className="policy-section-description">
          Web sitemizi kullanarak, bazı kişisel verilerinizi toplama yetkisi
          verirsiniz. Bu veriler, örneğin ad, e-posta adresi, ödeme bilgileri ve
          sosyal medya hesap bilgileri olabilir.
        </p>
      </section>

      <section className="policy-section">
        <h2 className="policy-section-title">Veri Kullanımı</h2>
        <p className="policy-section-description">
          Verileriniz, size hizmet sunabilmek, ödeme işlemleri yapmak ve
          analizler gerçekleştirmek amacıyla kullanılacaktır.
        </p>
      </section>

      <section className="policy-section">
        <h2 className="policy-section-title">Veri Koruma</h2>
        <p className="policy-section-description">
          Verileriniz, güvenli sunucularda şifrelenmiş şekilde saklanacaktır ve
          sadece yetkili personel tarafından erişilebilecektir.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
