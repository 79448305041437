import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faInstagram,
  faFacebook,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { Bar, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import "./AnalysisPage.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const AnalysisPage = () => {
  const [analysisData] = useState([
    {
      platform: "Twitter",
      username: "@exampleUser1",
      followers: 1200,
      followerGrowthRate: 2.5,
      posts: 350,
      likesAvg: 45,
      commentsAvg: 10,
      engagementRate: "5%",
    },
    {
      platform: "Twitter",
      username: "@exampleUser23",
      followers: 1100,
      followerGrowthRate: 2.5,
      posts: 350,
      likesAvg: 33,
      commentsAvg: 10,
      engagementRate: "9%",
    },
    {
      platform: "Instagram",
      username: "@exampleUser2",
      followers: 2500,
      followerGrowthRate: 4.2,
      posts: 200,
      likesAvg: 120,
      commentsAvg: 25,
      engagementRate: "8%",
    },
    {
      platform: "Youtube",
      username: "@exampleUser222",
      followers: 2500,
      followerGrowthRate: 4.2,
      posts: 200,
      likesAvg: 120,
      commentsAvg: 25,
      engagementRate: "8%",
    },
    {
      platform: "Instagram",
      username: "@exampleUser8",
      followers: 2500,
      followerGrowthRate: 4.2,
      posts: 200,
      likesAvg: 120,
      commentsAvg: 25,
      engagementRate: "8%",
    },
    {
      platform: "Facebook",
      username: "@exampleUser3",
      followers: 1800,
      followerGrowthRate: 3.1,
      posts: 300,
      likesAvg: 80,
      commentsAvg: 20,
      engagementRate: "6%",
    },
    {
      platform: "Facebook",
      username: "@exampleUser33",
      followers: 1500,
      followerGrowthRate: 3.1,
      posts: 300,
      likesAvg: 80,
      commentsAvg: 20,
      engagementRate: "6%",
    },
  ]);

  // Prepare data for the charts with unique labels per account
  const followersData = {
    labels: analysisData.map((data) => `${data.platform} (${data.username})`),
    datasets: [
      {
        label: "Followers",
        data: analysisData.map((data) => data.followers),
        backgroundColor: "rgba(54, 162, 235, 0.5)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  const engagementData = {
    labels: analysisData.map((data) => `${data.platform} (${data.username})`),
    datasets: [
      {
        label: "Engagement Rate",
        data: analysisData.map((data) => parseFloat(data.engagementRate)),
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)",
          "rgba(75, 192, 192, 0.5)",
          "rgba(153, 102, 255, 0.5)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  // Generate Recommendations based on individual account data
  const recommendations = analysisData.map((data) => {
    const engagementRate = parseFloat(data.engagementRate.slice(0, -1));
    const growthRate = data.followerGrowthRate;

    let recommendation = "";
    let icon;
    let bgColor;

    // Customize icon and background color based on platform
    switch (data.platform) {
      case "Twitter":
        icon = faTwitter;
        bgColor = "#1DA1F2";
        break;
      case "Instagram":
        icon = faInstagram;
        bgColor = "#E1306C";
        break;
      case "Facebook":
        icon = faFacebook;
        bgColor = "#3b5998";
        break;
      case "YouTube":
        icon = faYoutube;
        bgColor = "#FF0000";
        break;
      default:
        icon = null; // Or set a generic icon if available
        bgColor = "#888"; // Neutral color for unspecified platforms
    }

    if (engagementRate < 5) {
      recommendation =
        "Engagement rate is below average. Increase interactions with followers.";
    } else if (growthRate < 3) {
      recommendation =
        "Follower growth is slow. Try frequent updates or engagement posts.";
    } else {
      recommendation = "Strong performance; maintain current strategy.";
    }

    return {
      platform: data.platform,
      recommendation,
      icon,
      bgColor,
      username: data.username,
    };
  });

  return (
    <div className="analysis-container">
      <h2>Sosyal Medya Analizleri</h2>

      <div className="charts-container">
        {/* Followers Comparison */}
        <div className="chart-container">
          <h3>Takipçi Sayısı Karşılaştırması</h3>
          <Bar
            data={followersData}
            options={{
              responsive: true,
              plugins: { title: { display: true, text: "Takipçi Sayıları" } },
            }}
          />
        </div>

        {/* Engagement Rate Comparison */}
        <div className="chart-container">
          <h3>Etkileşim Oranı Karşılaştırması</h3>
          <Pie
            data={engagementData}
            options={{
              responsive: true,
              plugins: { title: { display: true, text: "Etkileşim Oranı" } },
            }}
          />
        </div>
      </div>

      {/* Analysis Table */}
      <table className="analysis-table">
        <thead>
          <tr>
            <th>Platform</th>
            <th>Kullanıcı Adı</th>
            <th>Takipçi Sayısı</th>
            <th>Takipçi Büyüme Oranı</th>
            <th>Gönderi Sayısı</th>
            <th>Ortalama Beğeni</th>
            <th>Ortalama Yorum</th>
            <th>Etkileşim Oranı</th>
          </tr>
        </thead>
        <tbody>
          {analysisData.map((data, index) => (
            <tr key={index}>
              <td>{data.platform}</td>
              <td>{data.username}</td>
              <td>{data.followers}</td>
              <td>{data.followerGrowthRate}%</td>
              <td>{data.posts}</td>
              <td>{data.likesAvg}</td>
              <td>{data.commentsAvg}</td>
              <td>{data.engagementRate}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Insights and Recommendations */}
      {/* Enhanced Insights and Recommendations */}
      <div className="recommendations-section">
        <h3>İçgörüler ve Tavsiyeler</h3>
        <div className="recommendations-list">
          {recommendations.map((rec, index) => (
            <div
              key={index}
              className="recommendation-card"
              style={{ backgroundColor: rec.bgColor }}
            >
              <FontAwesomeIcon icon={rec.icon} className="platform-icon" />
              <div className="recommendation-content">
                <h4>
                  {rec.platform} - {rec.username}
                </h4>
                <p>{rec.recommendation}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AnalysisPage;
