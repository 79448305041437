import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faInstagram,
  faFacebook,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import "./CustomePublishPage.css";

const CustomePublishPage = () => {
  const [content, setContent] = useState("");
  const [media, setMedia] = useState(null);
  const [tags, setTags] = useState("");
  const [platforms, setPlatforms] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState({});
  const [scheduleDate, setScheduleDate] = useState(
    new Date().toISOString().slice(0, 16)
  );
  const [posts, setPosts] = useState([]);

  // Linked Accounts
  const linkedAccounts = {
    Twitter: ["user_twitter1", "user_twitter2", "user_twitter3"],
    Instagram: ["user_instagram1", "user_instagram2"],
    Facebook: ["user_facebook1", "user_facebook2"],
    YouTube: ["user_youtube1", "user_youtube2"],
  };

  const availablePlatforms = [
    { name: "Twitter", icon: faTwitter },
    { name: "Instagram", icon: faInstagram },
    { name: "Facebook", icon: faFacebook },
    { name: "YouTube", icon: faYoutube },
  ];

  const handlePlatformSelection = (platform) => {
    setPlatforms((prevPlatforms) =>
      prevPlatforms.includes(platform)
        ? prevPlatforms.filter((p) => p !== platform)
        : [...prevPlatforms, platform]
    );
  };

  const handleAccountSelection = (platform, account) => {
    setSelectedAccounts((prevSelected) => {
      const updatedSelection = prevSelected[platform]
        ? prevSelected[platform].includes(account)
          ? prevSelected[platform].filter((a) => a !== account)
          : [...prevSelected[platform], account]
        : [account];
      return { ...prevSelected, [platform]: updatedSelection };
    });
  };

  const handleSelectAllAccounts = (platform) => {
    setSelectedAccounts((prevSelected) => {
      const allSelected =
        prevSelected[platform]?.length === linkedAccounts[platform].length;
      const updatedSelection = allSelected ? [] : linkedAccounts[platform];
      return { ...prevSelected, [platform]: updatedSelection };
    });
  };

  const handleMediaChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setMedia(URL.createObjectURL(file));
    }
  };

  const handleTagChange = (e) => {
    setTags(e.target.value);
  };

  const handleSchedulePost = () => {
    if (
      !content.trim() ||
      platforms.length === 0 ||
      Object.keys(selectedAccounts).length === 0
    ) {
      alert(
        "Lütfen gönderi içeriğini girin, en az bir platform seçin ve hesap seçin."
      );
      return;
    }

    const newPost = {
      content: content.trim(),
      platforms,
      selectedAccounts,
      scheduleDate,
      media,
      tags: tags.trim(),
      status: scheduleDate ? "Planlandı" : "Paylaşıldı",
    };

    setPosts((prevPosts) => [...prevPosts, newPost]);
    setContent("");
    setPlatforms([]);
    setSelectedAccounts({});
    setScheduleDate("");
    setMedia(null);
    setTags("");
  };

  return (
    <div className="container">
      <h2>Toplu ve Planlı Paylaşım</h2>

      {/* Medya (Fotoğraf, Video, GIF) */}
      <div className="media-container">
        <label htmlFor="media-upload" className="media-label">
          Medya Yükle (Fotoğraf/Video/GIF):
        </label>
        <input
          type="file"
          id="media-upload"
          accept="image/*,video/*,image/gif"
          onChange={handleMediaChange}
          className="media-input"
        />
        {media && (
          <div className="uploaded-media-container">
            <img src={media} alt="Uploaded media" className="uploaded-media" />
          </div>
        )}
      </div>

      {/* Gönderi İçeriği (Text) */}
      <textarea
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder="Gönderi içeriğini buraya girin..."
        className="textarea"
      />

      {/* Etiket Alanı (Tags) */}
      <div className="tags-container">
        <label htmlFor="tags" className="tags-label">
          Etiketler (Virgülle ayırın):
        </label>
        <input
          type="text"
          id="tags"
          value={tags}
          onChange={handleTagChange}
          placeholder="Örneğin: #yazılım, #teknoloji"
          className="tags-input"
        />
      </div>

      {/* Platform Seçimi */}
      <div className="platforms-container">
        <h4>Platformlar</h4>
        <div className="platform-options">
          {availablePlatforms.map((platform) => (
            <label key={platform.name} className="platform-label">
              <input
                type="checkbox"
                checked={platforms.includes(platform.name)}
                onChange={() => handlePlatformSelection(platform.name)}
              />
              <FontAwesomeIcon icon={platform.icon} className="platform-icon" />{" "}
              {platform.name}
            </label>
          ))}
        </div>
      </div>

      {/* Hesap Seçimi Tablosu */}
      {platforms.length > 0 && (
        <div className="accounts-container">
          <h4>Bağlantılı Hesaplar</h4>
          {platforms.map((platform) => (
            <div key={platform} className="account-platform">
              <h5>{platform}</h5>
              <label>
                <input
                  type="checkbox"
                  checked={
                    selectedAccounts[platform]?.length ===
                    linkedAccounts[platform].length
                  }
                  onChange={() => handleSelectAllAccounts(platform)}
                />
                Tümünü Seç
              </label>
              <table className="accounts-table">
                <thead>
                  <tr>
                    <th>Hesap</th>
                    <th>Seçili</th>
                  </tr>
                </thead>
                <tbody>
                  {linkedAccounts[platform].map((account) => (
                    <tr key={account}>
                      <td>{account}</td>
                      <td>
                        <input
                          type="checkbox"
                          checked={
                            selectedAccounts[platform]?.includes(account) ||
                            false
                          }
                          onChange={() =>
                            handleAccountSelection(platform, account)
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      )}

      {/* Planlama Tarihi */}
      <div className="schedule-container">
        <label>
          Planlanan Tarih ve Saat:
          <input
            type="datetime-local"
            value={scheduleDate}
            onChange={(e) => setScheduleDate(e.target.value)}
            className="date-input"
          />
        </label>
      </div>

      <button onClick={handleSchedulePost} className="button">
        {scheduleDate ? "Gönderiyi Planla" : "Hemen Paylaş"}
      </button>

      {/* Gönderi Önizleme Kartları */}
      <div className="posts-container">
        <h4>Planan Gönderi Listesi</h4>
        {posts.length > 0 ? (
          <div className="post-previews">
            {posts.map((post, index) => (
              <div key={index} className="post-card">
                <div className="post-header">
                  <div className="platform-icons">
                    {post.platforms.map((platform) => {
                      const icon = availablePlatforms.find(
                        (p) => p.name === platform
                      ).icon;
                      return (
                        <FontAwesomeIcon
                          key={platform}
                          icon={icon}
                          className="post-platform-icon"
                        />
                      );
                    })}
                  </div>
                  <div className="post-media">
                    {post.media && <img src={post.media} alt="Post media" />}
                  </div>
                </div>
                <div className="post-body">
                  <p>{post.content}</p>
                  {post.tags && (
                    <p>
                      <strong>Etiketler:</strong> {post.tags}
                    </p>
                  )}
                </div>
                <div className="post-footer">
                  <p>
                    <strong>Seçilen Hesaplar:</strong>{" "}
                    {JSON.stringify(post.selectedAccounts)}
                  </p>
                  <p>
                    <strong>Planlama Durumu:</strong> {post.status}
                  </p>
                  {post.scheduleDate && (
                    <p>
                      <strong>Planlanan Tarih:</strong> {post.scheduleDate}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>Henüz gönderi planlanmadı.</p>
        )}
      </div>
    </div>
  );
};

export default CustomePublishPage;
