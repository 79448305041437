import React from "react";
import Card from "../ui/Card";  // Card component'i daha önce oluşturduğumuz Card ile entegre
import "./PromotePage.css";

const PromotePage = () => {
  return (
    <div className="promote-container">
      <h2 className="page-title">Hizmetlerimiz</h2>
      <div className="card-container">
        <Card
          title="Sosyal Medya Yönetimi"
          description="Tüm sosyal medya hesaplarınızı etkili bir şekilde yönetin."
          imageUrl="https://plus.unsplash.com/premium_photo-1683583961441-511f9498e6ed?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3"
        />
        <Card
          title="Dijital Pazarlama"
          description="Dijital dünyada markanızı öne çıkaracak stratejiler geliştirin."
          imageUrl="https://images.unsplash.com/photo-1557838923-2985c318be48?q=80&w=1631&auto=format&fit=crop&ixlib=rb-4.0.3"
        />
        {/* Diğer Card bileşenleri buraya eklenebilir */}
      </div>
    </div>
  );
};

export default PromotePage;
