import React from "react";
import "./DataDeletionPolicy.css";

const DataDeletionPolicy = () => {
  return (
    <div className="data-deletion-policy-container">
      <h1 className="data-deletion-policy-title">Veri Silme Politikası</h1>
      <p className="data-deletion-policy-description">
        Kullanıcılarımızın kişisel verilerinin silinmesi, GDPR ve diğer
        düzenlemelere uygun şekilde yapılacaktır. Hesap silme talebi oluşturduğunuzda,
        tüm kişisel verileriniz kalıcı olarak silinir.
      </p>

      <section className="policy-section">
        <h2 className="policy-section-title">Silme Talebi</h2>
        <p className="policy-section-description">
          Kullanıcılar, hesabını silmek istediklerinde bize başvuru yapabilir ve
          verilerinin silinmesi talep edebilirler.
        </p>
      </section>

      <button className="delete-request-button">Hesabımı ve Verilerimi Sil</button>
    </div>
  );
};

export default DataDeletionPolicy;
