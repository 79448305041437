import React from "react";
import promoteVideo from "../assets/videos/promote.mp4"; // Update the path as needed
import "./VideoPage.css";

const VideoPage = () => {
  return (
    <div className="video-container">
      <video autoPlay loop muted className="homepage-video">
        <source src={promoteVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="overlay">
       { /*<h1>Promote Your Brand</h1>*/}
        {/*<p>Engage your audience with impactful visuals and media.</p>*/}
      </div>
    </div>
  );
};

export default VideoPage;
