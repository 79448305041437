import React from "react";
import "./OurServicePage.css";

const servicesData = [
  {
    title: "Web Geliştirme",
    description: "Özelleştirilmiş, güvenilir ve kullanıcı dostu web çözümleri sunuyoruz.",
    icon: "🌐",
  },
  {
    title: "Mobil Uygulama Geliştirme",
    description: "iOS ve Android platformları için hızlı ve etkili mobil uygulama geliştirme hizmetleri.",
    icon: "📱",
  },
  {
    title: "Dijital Pazarlama",
    description: "Markanızı daha geniş kitlelere ulaştırmak için dijital stratejiler oluşturuyoruz.",
    icon: "📈",
  },
  {
    title: "Veri Analitiği",
    description: "Verilerinizi analiz ederek iş süreçlerinizi optimize etmenize yardımcı oluyoruz.",
    icon: "📊",
  },
];

const OurServicePage = () => {
  return (
    <div className="services-page">
      <div className="services-container">
        <h1 className="services-title">Hizmetlerimiz</h1>
        <div className="services-list">
          {servicesData.map((service, index) => (
            <div className="service-card" key={index}>
              <div className="service-icon">{service.icon}</div>
              <h2 className="service-title">{service.title}</h2>
              <p className="service-description">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurServicePage;
