const API_BASE_URL = "http://localhost:5000/profile";

// Helper function to get the token from local storage or elsewhere
const getAuthToken = () => {
  return localStorage.getItem("token"); // Adjust according to your token storage method
};

// Helper function to handle API requests
const apiRequest = async (url, options) => {
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      const errorData = await response.json();
      const errorMessage = errorData.message || "Bir hata oluştu.";
      throw new Error(errorMessage);
    }
    return await response.json();
  } catch (error) {
    throw new Error(
      error.message || "Sunucu hatası. Lütfen daha sonra tekrar deneyin."
    );
  }
};

// Profil bilgilerini almak
export const getProfile = async () => {
  const url = `${API_BASE_URL}/`;
  const token = getAuthToken(); // Get the auth token from localStorage

  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add token to the header
    },
  };

  return apiRequest(url, options);
};

// Profil bilgilerini güncellemek
export const updateProfile = async (userData) => {
  const url = `${API_BASE_URL}/`;
  const token = getAuthToken(); // Get the auth token from localStorage

  const options = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add token to the header
    },
    body: JSON.stringify(userData),
  };

  return apiRequest(url, options);
};

// Kullanıcı şifresini değiştirmek
export const changePassword = async (oldPassword, newPassword) => {
  const url = `${API_BASE_URL}/change-password`;
  const token = getAuthToken(); // Get the auth token from localStorage
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add token to the header
    },
    body: JSON.stringify({ oldPassword, newPassword }),
  };

  return apiRequest(url, options);
};

// Profil resmi güncellemek (Opsiyonel)
export const updateProfilePicture = async (imageData) => {
  const url = `${API_BASE_URL}/update-profile-picture/}`;
  const token = getAuthToken(); // Get the auth token from localStorage

  const formData = new FormData();
  formData.append("image", imageData);

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`, // Add token to the header
    },
    body: formData,
  };

  return apiRequest(url, options);
};
