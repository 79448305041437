import React, { createContext, useState, useEffect } from "react";

// Create the AuthContext
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [token, setToken] = useState(null);

  // Load user and token from localStorage if they exist
  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    const storedUser = localStorage.getItem("user");
    const storedToken = localStorage.getItem("token");
    if (storedUser && storedToken) {
      setUserId(JSON.parse(storedUserId));
      setUser(JSON.parse(storedUser));
      setToken(storedToken);
    }
  }, []);

  const login = (userData, token) => {
    setUserId(userData.id);
    setUser(userData);
    setToken(token);
    localStorage.setItem("userId", JSON.stringify(userData.id));
    localStorage.setItem("user", JSON.stringify(userData));
    localStorage.setItem("token", token);
  };

  const logout = () => {
    setUserId(null);
    setUser(null);
    setToken(null);
    localStorage.removeItem("userId");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
  };

  return (
    <AuthContext.Provider value={{ userId, user, token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
