import React from "react";
import "./HeroSection.css"; // CSS dosyasını içe aktar
import Button from "./Button";
import ShowGif from "../assets/videos/2.gif";

const HeroSection = () => {
  return (
    <div className="hero-parallax">
      <div className="hero-content">
        <h1>Hizmetlerimizle Sosyal Medya Performansınızı Artırın!</h1>
        <p>
          Yönetim, analiz ve strateji geliştirme konularında profesyonel destek
          alın.
        </p>
        <Button type="promote" text="Hizmetlerimizi Keşfedin" />
      </div>
      <div className="hero-gif-container">
        <img src={ShowGif} alt="Moving Globe" />
      </div>
    </div>
  );
};

export default HeroSection;
