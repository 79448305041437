// api/platform.js - Mock API işlemleri

// Sosyal medya platformu ile bağlantı kurmak için mock fonksiyon
export const connectPlatform = (platformName) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      // Mock veri olarak başarılı bir bağlantı simüle ediyoruz
      if (platformName) {
        resolve({
          token: `mock-token-${platformName}`, // Token'ı mock bir değerle döndürüyoruz
          connectedAt: new Date().toLocaleString(), // Bağlantı zamanı
          expiresAt: new Date(Date.now() + 3600 * 1000).toLocaleString(), // Token süresi (1 saat)
        });
      } else {
        reject("Platform adı geçerli değil.");
      }
    }, 1500); // 1.5 saniye bekleme (API çağrısını simüle ediyoruz)
  });
};

// Hesap silme işlemi için mock fonksiyon
export const removeAccount = (accountId) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      // Mock veri olarak hesap silme işlemini simüle ediyoruz
      if (accountId) {
        resolve(`Account with ID ${accountId} removed`);
      } else {
        reject("Hesap ID'si geçerli değil.");
      }
    }, 1000); // 1 saniye bekleme (Hesap silme işlemini simüle ediyoruz)
  });
};

// Refresh token işlemi için mock fonksiyon
export const refreshToken = (accountId) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      // Mock veri olarak token yenileme işlemini simüle ediyoruz
      if (accountId) {
        resolve({
          newToken: `mock-new-token-${accountId}`, // Yeni token'ı mock bir değerle döndürüyoruz
          refreshedAt: new Date().toLocaleString(), // Token yenileme zamanı
          expiresAt: new Date(Date.now() + 3600 * 1000).toLocaleString(), // Yeni token süresi (1 saat)
        });
      } else {
        reject("Hesap ID'si geçerli değil.");
      }
    }, 1200); // 1.2 saniye bekleme (Token yenileme işlemini simüle ediyoruz)
  });
};
