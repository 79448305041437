import React, { useState } from "react";
import "./SettingsPage.css";
import { FaLock, FaShieldAlt, FaSun, FaMoon, FaRainbow } from "react-icons/fa"; // Import icons for better visual context

const SettingsPage = () => {
  const [theme, setTheme] = useState("light");

  const handleThemeChange = (newTheme) => {
    setTheme(newTheme);
    document.body.className = newTheme; // Apply the selected theme to the body
  };

  return (
    <div className="settings-container">
      <h2 className="settings-title">Ayarlar</h2>

      {/* Güvenlik Ayarları */}
      <section className="settings-section">
        <h3 className="settings-subtitle">
          <FaLock /> Güvenlik
        </h3>
        <button className="settings-button">Şifre Değiştir</button>
        <button className="settings-button">
          İki Faktörlü Kimlik Doğrulama
        </button>
      </section>

      {/* Gizlilik Ayarları */}
      <section className="settings-section">
        <h3 className="settings-subtitle">
          <FaShieldAlt /> Gizlilik
        </h3>
        <p className="settings-description">
          Veri gizliliği tercihlerinizi düzenleyin:
        </p>
        <button className="settings-button">Gizlilik Ayarlarını Yönet</button>
      </section>

      {/* Kullanım Şartları */}
      <section className="settings-section">
        <h3 className="settings-subtitle">Kullanım Şartları</h3>
        <p className="settings-description">
          Uygulamanın hizmet şartlarını okuyun:
        </p>
        <button className="settings-button">Şartları Görüntüle</button>
      </section>

      {/* Tema Modları */}
      <section className="settings-section">
        <h3 className="settings-subtitle">
          <FaSun /> Tema Modları
        </h3>
        <div className="theme-buttons">
          <button
            onClick={() => handleThemeChange("light")}
            className={`settings-button ${theme === "light" ? "active" : ""}`}
          >
            Aydınlık Mod
          </button>
          <button
            onClick={() => handleThemeChange("dark")}
            className={`settings-button ${theme === "dark" ? "active" : ""}`}
          >
            Karanlık Mod
          </button>
          <button
            onClick={() => handleThemeChange("rainbow")}
            className="settings-button rainbow-button"
          >
            <FaRainbow /> Gökkuşağı Modu
          </button>
        </div>
      </section>
    </div>
  );
};

export default SettingsPage;
