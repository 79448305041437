import React, { useState, useEffect } from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"; // Material Icon

const GoToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Scroll olayı ile butonun görünürlüğünü kontrol et
  const checkScrollTop = () => {
    if (!isVisible && window.pageYOffset > 400) {
      setIsVisible(true);
    } else if (isVisible && window.pageYOffset <= 400) {
      setIsVisible(false);
    }
  };

  // Scroll eventini dinleyin
  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, [isVisible]);

  // Sayfayı en üste kaydır
  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    isVisible && (
      <button onClick={goToTop} style={styles.button} className="go-to-top-btn">
        <ArrowUpwardIcon fontSize="large" style={{ color: "white" }} />
      </button>
    )
  );
};

// Buton için stil
const styles = {
  button: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    backgroundColor: "#007BFF", // Modern mavi
    color: "white",
    border: "none",
    borderRadius: "50%",
    width: "60px",
    height: "60px",
    fontSize: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
    transition: "all 0.3s ease",
    opacity: 0.8,
  },
};

export default GoToTop;
