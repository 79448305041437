import React, { useState, useEffect } from "react";
import { connectPlatform, removeAccount, refreshToken } from "../api/platform";
import Button from "../ui/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faInstagram,
  faFacebook,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import "./SocialMediaConnectPage.css";

// Platform data for consistency and easy updates
const platformsData = [
  { id: 1, name: "Instagram", icon: faInstagram },
  { id: 2, name: "Twitter", icon: faTwitter },
  { id: 3, name: "Facebook", icon: faFacebook },
  { id: 4, name: "YouTube", icon: faYoutube },
];

const SocialMediaConnectPage = () => {
  const [accounts, setAccounts] = useState([]);
  const [error, setError] = useState("");
  const [connecting, setConnecting] = useState(false);
  const [success, setSuccess] = useState("");

  // Fetch previously connected accounts from local storage or an API on component mount
  useEffect(() => {
    // Assuming you might store account data in local storage or fetch from API
    const storedAccounts = JSON.parse(localStorage.getItem("accounts")) || [];
    setAccounts(storedAccounts);
  }, []);

  const handleConnect = async (platform) => {
    setConnecting(true);
    setError(""); // Reset errors
    setSuccess(""); // Reset success messages

    try {
      const token = await connectPlatform(platform.name);
      const newAccount = {
        id: accounts.length + 1,
        platform: platform.name,
        username: `@${platform.name}User`,
        status: "Bağlı",
        connectionDate: new Date().toLocaleDateString(),
        tokenExpiry: new Date(
          new Date().getTime() + 3600 * 1000
        ).toLocaleDateString(),
        token,
      };

      setAccounts([...accounts, newAccount]);
      localStorage.setItem(
        "accounts",
        JSON.stringify([...accounts, newAccount])
      );
      setConnecting(false);
      setSuccess(`${platform.name} hesabı başarıyla bağlandı!`);
    } catch (error) {
      setError("Bağlantı kurulurken bir hata oluştu.");
      setConnecting(false);
    }
  };

  const handleRemoveAccount = async (accountId) => {
    setConnecting(true);
    setError(""); // Reset errors
    setSuccess(""); // Reset success messages

    try {
      await removeAccount(accountId);
      const updatedAccounts = accounts.filter(
        (account) => account.id !== accountId
      );
      setAccounts(updatedAccounts);
      localStorage.setItem("accounts", JSON.stringify(updatedAccounts));
      setConnecting(false);
      setSuccess("Hesap başarıyla kaldırıldı!");
    } catch (error) {
      setError("Hesap kaldırılırken bir hata oluştu.");
      setConnecting(false);
    }
  };

  const handleRefreshToken = async (accountId) => {
    setConnecting(true);
    setError(""); // Reset errors
    setSuccess(""); // Reset success messages

    try {
      await refreshToken(accountId);
      const updatedAccounts = accounts.map((account) =>
        account.id === accountId
          ? {
              ...account,
              tokenExpiry: new Date(
                new Date().getTime() + 3600 * 1000
              ).toLocaleDateString(),
            }
          : account
      );
      setAccounts(updatedAccounts);
      localStorage.setItem("accounts", JSON.stringify(updatedAccounts));
      setConnecting(false);
      setSuccess("Token başarıyla yenilendi!");
    } catch (error) {
      setError("Token yenilenirken bir hata oluştu.");
      setConnecting(false);
    }
  };

  return (
    <div className="container">
      <h2 className="header-title">Sosyal Medya Hesapları</h2>

      {/* Platform Connection Cards */}
      <div className="platform-list">
        {platformsData.map((platform) => (
          <div key={platform.id} className="platform-card">
            <FontAwesomeIcon icon={platform.icon} size="3x" className="icon" />
            <Button
              text="Bağla"
              onClick={() => handleConnect(platform)}
              disabled={connecting}
            />
          </div>
        ))}
      </div>

      {/* Success/Error messages */}
      {success && <p className="success">{success}</p>}
      {error && <p className="error">{error}</p>}

      {/* Displaying Connected Accounts */}
      <div className="accounts">
        <h3>Bağlı Hesaplar</h3>
        <table className="table">
          <thead>
            <tr>
              <th>Platform</th>
              <th>Kullanıcı Adı</th>
              <th>Durum</th>
              <th>Bağlantı Tarihi</th>
              <th>Token Süresi</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {accounts.length > 0 ? (
              accounts.map((account) => (
                <tr key={account.id}>
                  <td>{account.platform}</td>
                  <td>{account.username}</td>
                  <td>{account.status}</td>
                  <td>{account.connectionDate}</td>
                  <td>{account.tokenExpiry}</td>
                  <td>
                    <Button
                      text="Bağlantıyı Kaldır"
                      onClick={() => handleRemoveAccount(account.id)}
                    />
                    <Button
                      text="Token Yenile"
                      onClick={() => handleRefreshToken(account.id)}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="no-accounts">
                  Henüz bağlantı kurulmuş bir hesap yok.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SocialMediaConnectPage;
