import React from 'react';
import './Button.css';

const Button = ({ 
    text, 
    onClick, 
    isLoading = false, 
    type = 'primary', 
    size = 'medium', 
    disabled = false, 
    icon = null, 
    fullWidth = false,  // New prop for full-width buttons
    rounded = false     // New prop for rounded corners
}) => {
    const baseClasses = [
        'button', 
        `button--${type}`, 
        `button--${size}`, 
        disabled || isLoading ? 'button--disabled' : '',
        fullWidth ? 'button--full-width' : '', 
        rounded ? 'button--rounded' : ''
    ].join(' ');

    return (
        <button
            className={baseClasses}
            onClick={onClick}
            disabled={disabled || isLoading}
        >
            {isLoading ? <span className="button__spinner" /> : icon && <span className="button__icon">{icon}</span>}
            {text}
        </button>
    );
};

export default Button;
